import React from 'react';
import Biography from './Biography';
const AboutMe = () => {
	return (
		<section class='mt-5 bg-gray'>
			<div class='max-w-6xl mx-auto px-4 sm:px-6 lg:px-8'>
				<div class='lg:text-center'>
					<Biography />
				</div>
			</div>
		</section>
	);
};

export default AboutMe;
