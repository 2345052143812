import React from 'react';

const Biography = () => {
	return (
		<div>
			<p class='text-5xl leading-8 font-extrabold tracking-tight text-gray-900'>
				Hi, I'm Altaaf Ali
			</p>
			<h1 className='mt-5 mb-2 text-1xl font-semibold tracking-tight leading-none text-indigo-600 md:text-2xl lg:text-2xl'>
				Experience:
			</h1>
			<p class='max-w-2xl text-lg text-gray-700 lg:mx-auto'>
				I'm a Software Engineer with over 5 years of programming experience developing{' '}
				<span class='font-bold'>
					Full-Stack Web Applications, Desktop Applications, Web Scrapers & Crawlers, Penetration
					Testing Tools & Big Data Analytics projects.
				</span>{' '}
				I'm passionate about leveraging the latest technologies to create user-friendly and
				performant software solutions.
			</p>
			<p class='mt-1 max-w-2xl text-lg text-gray-700 lg:mx-auto'>
				Over the past 4-5 years, I have gained extensive experience from freelance development,
				working on a wide range of projects. Some of the projects that I've worked on included{' '}
				<span class='font-bold'>
					Reverse engineering API's, Multi Threaded programs to handle large amounts of repetitive
					tasks, OCR to bypass image/text based security checks.
				</span>{' '}
				Some of the programming languages that I've used and am proficient in include{' '}
				<span class='font-bold'>Python, SQL, Javascript, Go</span>
			</p>
			<h1 className='mt-2 mb-2 text-1xl font-semibold tracking-tight leading-none text-indigo-600 md:text-2xl lg:text-2xl'>
				Education:
			</h1>
			<p class='max-w-2xl text-lg text-gray-700 lg:mx-auto'>
				I have completed a{' '}
				<span class='font-bold'>
					Bachelor's Degree in Computer Science with a concentration in Big Data Management and
					Analytics
				</span>
				. During my undergraduate degree, I took a Machine Learning course as well as a NLP course
				which sparked my interest to pursue a degree in Data Science. After graduating with my
				bachelors, I went on to{' '}
				<span class='font-bold'>complete a Masters Degree in Data Science</span>.
			</p>
			<h1 className='mt-2 mb-2 text-1xl font-semibold tracking-tight leading-none text-indigo-600 md:text-2xl lg:text-2xl'>
				Post Graduation:
			</h1>
			<p class='max-w-2xl text-lg text-gray-700 lg:mx-auto'>
				Since graduating I've found that I enjoy developing and building applications much more than
				I do solely working with big data, though I still find that the experience that I've gained
				from pursuing big data analytics has helped me become a better programmer. I've been{' '}
				<span class='font-bold'>focusing primarly on Software Development </span>and have been
				incorporating my knowledge in ML/AI when applicable.
			</p>
		</div>
	);
};

export default Biography;
