import React from 'react';

const ScrollToPos = ({ pos }) => {
	return (
		<a
			href={pos}
			className='flex justify-center items-center w-12 h-12 bg-blue-900 text-white rounded-full animate-bounce'
			style={{ position: 'fixed', bottom: '2rem', right: '2rem' }}>
			<img src='/assets/images/arrow_up.png' alt='Scroll to top' className='h-6 w-6' />
		</a>
	);
};

export default ScrollToPos;
