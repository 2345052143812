import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
const ContactForm = () => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		message: '',
	});
	const { name, email, message } = formData;
	const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
	const handleSubmit = (event) => {
		event.preventDefault();
		if (!name || !email || !message) {
			toast.error('Please fill in all required fields');
			return;
		}
		fetch('/api/contact', {
			method: 'POST',
			body: JSON.stringify({ name, email, message }),
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				toast.success('Message successfully sent! Will respond to you shortly.');
			})
			.catch((error) => {
				toast.error('API is unavailable currently');
			});
	};
	return (
		<section id='Contact' className='pt-5 bg-gray'>
			<div className='container px-5 py-24 mx-auto text-blue-900'>
				<div className='flex flex-col text-center w-full mb-12'>
					<h1 className='mb-4 text-4xl font-bold tracking-tight leading-none md:text-5xl lg:text-6xl'>
						Contact Me
					</h1>
					<p className='mb-2 text-lg font-normal'>Looking to collaborate?</p>
				</div>
				<div className='lg:w-1/2 md:w-2/3 mx-auto'>
					<div className='flex flex-wrap -m-2'>
						<div className='p-2 w-1/2'>
							<div className='relative'>
								<label htmlFor='name' className='leading-7 text-sm'>
									Name <span className='text-red-500'>*</span>
								</label>
								<input
									required
									type='text'
									id='name'
									name='name'
									value={name}
									onChange={(e) => onChange(e)}
									className='w-full bg-gray-100 rounded border border-blue-900 focus:border-blue-900 text-base outline-none text-gray-900 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
								/>
							</div>
						</div>
						<div className='p-2 w-1/2'>
							<div className='relative'>
								<label htmlFor='email' className='leading-7 text-sm'>
									Email <span className='text-red-500'>*</span>
								</label>
								<input
									required
									type='email'
									id='email'
									name='email'
									value={email}
									onChange={(e) => onChange(e)}
									className='w-full bg-gray-100 rounded border border-blue-900 focus:border-blue-900 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
								/>
							</div>
						</div>
						<div className='p-2 w-full'>
							<div className='relative'>
								<label htmlFor='message' className='leading-7 text-sm'>
									Message <span className='text-red-500'>*</span>
								</label>
								<textarea
									required
									id='message'
									name='message'
									value={message}
									onChange={(e) => onChange(e)}
									className='w-full bg-gray-100 rounded border border-blue-900 focus:border-blue-900 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out'></textarea>
							</div>
						</div>
						<div className='p-2 w-full'>
							<button
								className='flex mx-auto py-2 px-4 text-lg font-medium text-white transition duration-500 ease-in-out transform bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
								onClick={handleSubmit}>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactForm;
