import React from 'react';

const NotFoundPage = () => {
	return (
		<section className='bg-white'>
			<div className='py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16'>
				<h1 className='mb-4 text-4xl font-extrabold tracking-tight leading-none text-blue-900 md:text-5xl lg:text-6xl'>
					Page not found!
				</h1>
			</div>
		</section>
	);
};

export default NotFoundPage;
