import { useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

const ParticlesBackground = () => {
	const particlesInit = useCallback(async (engine) => {
		console.log(engine);
		await loadFull(engine);
	}, []);

	return (
		<div style={{ position: 'relative' }}>
			<Particles
				id='tsparticles'
				init={particlesInit}
				options={{
					background: {
						color: {
							value: 'transparent',
						},
					},
					fpsLimit: 60,
					interactivity: {
						events: {
							onClick: {
								enable: true,
								mode: 'push',
							},
							onHover: {
								enable: true,
								mode: 'repulse',
							},
							resize: true,
						},
						modes: {
							push: {
								quantity: 4,
							},
							repulse: {
								distance: 200,
								duration: 0.4,
							},
						},
					},
					particles: {
						color: {
							value: ['#060b3c', '#0a0e4e', '#141a77', '#1e277e'],
						},
						links: {
							color: '#1e277e',
							distance: 150,
							enable: true,
							opacity: 1,
							width: 1,
						},
						collisions: {
							enable: true,
						},
						move: {
							directions: 'none',
							enable: true,
							outModes: {
								default: 'bounce',
							},
							random: true,
							speed: 3,
							straight: false,
						},
						number: {
							density: {
								enable: true,
								area: 800,
							},
							value: 10,
						},
						opacity: {
							value: 1,
						},
						shape: {
							type: 'circle',
						},
						size: {
							value: { min: 1, max: 5 },
						},
					},
					detectRetina: true,
				}}
			/>
		</div>
	);
};

export default ParticlesBackground;
