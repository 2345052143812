import ContactForm from './ContactForm';
import ScrollToPos from '../../components/ScrollToPos';
import Projects from './ProjectsSection';
import Landing from './Landing';
import Langauges from './Languages';
import { useState, useEffect } from 'react';
const HomePage = () => {
	const [showScrollButton, setShowScrollButton] = useState(false);

	useEffect(() => {
		function handleScroll() {
			if (window.pageYOffset > 0) {
				setShowScrollButton(true);
			} else {
				setShowScrollButton(false);
			}
		}
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);
	return (
		<div>
			<Landing />
			<Langauges />
			<Projects />
			<ContactForm />
			{showScrollButton && <ScrollToPos pos={'#Landing'} />}
		</div>
	);
};

export default HomePage;
