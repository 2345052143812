import React from 'react';

const Landing = () => {
	const Links = [
		{
			Name: 'Resume',
			img: '/assets/icons/resume.png',
			href: '/assets/documents/Resume.pdf',
		},
		{
			Name: 'Github',
			img: '/assets/icons/github.png',
			href: 'https://github.com/Altaaaf',
		},
		{
			Name: 'LinkedIn',
			img: '/assets/icons/linkedin.png',
			href: 'https://www.linkedin.com/in/altaafali/',
		},
		{
			Name: 'Email',
			img: '/assets/icons/email.png',
			href: 'mailto:Altaaf-Ali@outlook.com',
		},
	];
	return (
		<section
			id='Landing'
			className='min-h-screen bg-black flex flex-col justify-center items-center'>
			<div className='text-center'>
				<h1 className='text-5xl font-bold text-white mb-2'>Altaaf Ali</h1>
				<p className='text-xl text-white mb-8'>Software Engineer</p>
				<div className='flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4'>
					{Links.map((link) => (
						<a
							href={link.href}
							target='_blank'
							rel='noreferrer'
							key={link.Name}
							className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow flex items-center'>
							<img src={link.img} alt='' className='mr-2 w-6 h-6 object-contain' />
							<span className='text-lg'>{link.Name}</span>
						</a>
					))}
				</div>
				<div className='mt-10'>
					<a href='#Projects' className='flex justify-center items-center animate-bounce'>
						<img src='/assets/images/arrow_down.png' alt='' className='h-6 w-6' />
						<span className='ml-2 text-2xl font-semibold text-white'>View Projects</span>
					</a>
				</div>
			</div>
		</section>
	);
};

export default Landing;
