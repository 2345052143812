import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router';
import { ToastContainer } from 'react-toastify';
import './styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ToastContainer />
		<Router />
	</React.StrictMode>,
);
