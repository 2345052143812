import React from 'react';

const Languages = () => {
	return (
		<div className='bg-blue-900 py-10'>
			<div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
				<div className='flex justify-center'>
					<div className='grid grid-cols-7 gap-8'>
						<a
							href='https://en.wikipedia.org/wiki/Python_(programming_language)'
							target='_blank'
							rel='noreferrer'>
							<img src='/assets/images/python.png' alt='' className='object-contain h-12' />
						</a>
						<a href='https://en.wikipedia.org/wiki/SQL' target='_blank' rel='noreferrer'>
							<img src='/assets/images/sql.png' alt='' className='object-contain h-12' />
						</a>
						<a href='https://en.wikipedia.org/wiki/NoSQL' target='_blank' rel='noreferrer'>
							<img src='/assets/images/nosql.png' alt='' className='object-contain h-12' />
						</a>
						<a
							href='https://en.wikipedia.org/wiki/Go_(programming_language)'
							target='_blank'
							rel='noreferrer'>
							<img src='/assets/images/go.png' alt='' className='object-contain h-12' />
						</a>
						<a href='https://en.wikipedia.org/wiki/JavaScript' target='_blank' rel='noreferrer'>
							<img src='/assets/images/javascript.png' alt='' className='object-contain h-12' />
						</a>
						<a href='https://en.wikipedia.org/wiki/HTML' target='_blank' rel='noreferrer'>
							<img src='/assets/images/html.png' alt='' className='object-contain h-12' />
						</a>
						<a href='https://en.wikipedia.org/wiki/CSS' target='_blank' rel='noreferrer'>
							<img src='/assets/images/css.png' alt='' className='object-contain h-12' />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Languages;
