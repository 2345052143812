import React from 'react';
import { Fragment } from 'react';
const Project = ({ project, index }) => {
	const imageElement = (
		<div style={{ maxWidth: '800px', maxHeight: '600px', overflow: 'hidden' }}>
			<a href={project.img} target='_blank' rel='noopener noreferrer'>
				<img
					className='object-left w-128 h-128 shadow-md shadow-2xl'
					src={project.img}
					alt='project_image'
					style={{ transform: 'scale(1)', transition: 'transform .2s ease-in-out' }}
					onMouseOver={(e) => {
						e.currentTarget.style.transform = 'scale(0.95)';
					}}
					onMouseOut={(e) => {
						e.currentTarget.style.transform = 'scale(1)';
					}}
				/>
			</a>
		</div>
	);

	const repositoryElement = project.href ? (
		<a
			href={project.href}
			target='_blank'
			rel='noreferrer'
			className='inline-flex justify-center items-center py-3 px-5 py-2 px-4 text-lg font-medium text-white transition duration-500 ease-in-out transform bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
			Visit Project Repository
		</a>
	) : (
		<button className='inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white cursor-not-allowed rounded-lg bg-red-800'>
			Private Repository
		</button>
	);

	const detailsElement = (
		<div className='p-4 text-left'>
			<h4 className='text-3xl font-bold'>{project.title}</h4>
			<p className='mb-2 leading-normal'>{project.content}</p>
			{repositoryElement}
		</div>
	);
	return (
		<>
			{index % 2 === 0 ? (
				<>
					{imageElement}
					{detailsElement}
				</>
			) : (
				<>
					{detailsElement}
					{imageElement}
				</>
			)}
		</>
	);
};

const Projects = () => {
	const Projects = [
		{
			title: 'Account Manager',
			img: '/assets/projects/account_manager.png',
			content:
				'Python application that allows you to securely store and retrieve account information using AES-256-GCM encryption. Useful for saving account information locally in a central location that is only accessible to yourself.',
			href: 'https://github.com/Altaaaf/Account-Manager',
		},
		{
			title: 'Proxy Checker',
			img: '/assets/projects/proxy_checker.gif',
			content:
				'Fast, lightweight asynchronous proxy checker that checks HTTP, HTTPS, SOCKS4, SOCKS5 proxies. Using minimal resources the program can check as much as 25,000 proxies per minute.',
			href: 'https://github.com/Altaaaf/Proxy-Checker',
		},
		{
			title: 'SQLite Visualizer',
			img: '/assets/projects/sqlite_visualizer.gif',
			content:
				'Python GUI application tool to visualize and modify SQLite databases - supports all CRUD databaseabase operations.',
			href: 'https://github.com/Altaaaf/SQLite-Visualizer',
		},
		{
			title: 'Omegle Chat Bot',
			img: '/assets/projects/omegle_chatbot.gif',
			content:
				'Application utilizing Selenium to interact with web browser and using NLTK library to create realistic conversation with users. Personally used this project to communicate with random users of specific communities to collect data on user opinions on different topics.',
		},
		{
			title: 'Account Generator & Verifier',
			img: '/assets/projects/account_generator.png',
			content:
				'Registers and email verifies new accounts on a popular gaming website using requests. Made use of IMAP libraries to interact with email services and requests to communicate with websites REST API. Difficult project to make as there were many security checks that needed to be bypassed to avoid freshly created accounts from being flagged or banned from game servers.',
		},
		{
			title: 'Image Watermarker Tool',
			img: '/assets/projects/image_watermarker.gif',
			content:
				'A Python script to add watermark logos to single images or all images in a specified directory, useful for adding unique signatures to prevent people from claiming your work as their own.',
			href: 'https://github.com/Altaaaf/Image-watermarker',
		},
		{
			title: 'Crypto Price Tracker',
			img: '/assets/projects/crypto_price_tracker.gif',
			content:
				'A Golang tool using multi threading to track the prices of cryptocurrency tokens and receiving alerts when the price of a token goes above a given threshold. Lightweight tool which runs in the background and makes a distinct sound to alert you that a token you are monitoring as met your threshold.',
			href: 'https://github.com/Altaaaf/Crypto-currency-price-tracker',
		},
		{
			title: 'Youtube Thumbnail Downloader',
			img: '/assets/projects/thumbnail_downloader.gif',
			content:
				'A tool made using Go to download the highest resolution thumbnails available for any given YouTube video url.',
			href: 'https://github.com/Altaaaf/Youtube-Thumbnail-Downloader',
		},
		{
			title: 'Movie Reviewer',
			img: '/assets/projects/movie_reviewer.gif',
			content:
				'A python flask project which allows users to search for a movie by name or IMDb ID and comment their thoughts on a movie or show which other users can view.',
			href: 'https://github.com/Altaaaf/Movie-Reviewer',
		},
	];
	return (
		<section id='Projects' className='pt-10 min-h-screen bg-gray'>
			<div className='container mx-auto text-blue-900'>
				<div className='text-center'>
					<h1 className='mb-4 text-4xl font-bold tracking-tight leading-none text-blue-900 md:text-5xl lg:text-6xl'>
						Projects
					</h1>
					<p className='mb-16 text-lg font-normal'>
						Some projects that I've previously worked on - find more on my GitHub page
					</p>
				</div>
				<div className='grid grid-cols-2 gap-x-3 gap-y-8'>
					{Projects.map((project, index) => (
						<Fragment key={index}>
							<Project project={project} index={index} />
						</Fragment>
					))}
				</div>
			</div>
		</section>
	);
};
export default Projects;
