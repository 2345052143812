import React from 'react';
import { useLocation } from 'react-router-dom';
const Footer = () => {
	const location = useLocation();
	const isAboutPage = location.pathname === '/About';
	return (
		<footer className='bg-white rounded-lg shadow m-4'>
			<div className='w-full max-w-screen-xl mx-auto p-4 md:py-8'>
				<div className='sm:flex sm:items-center sm:justify-between'>
					<a href='/' className='flex items-center mb-4 sm:mb-0'>
						<img src='/assets/icons/profile.png' className='h-8 mr-3' alt='' />
						<span className='self-center text-2xl font-semibold whitespace-nowrap'>altaaf.dev</span>
					</a>
					<ul className='flex flex-wrap items-center mb-6 text-sm font-medium text-blue-900 sm:mb-0'>
						<li>
							<a href={isAboutPage ? '/' : '/About'} className='mr-4 hover:underline md:mr-6'>
								{isAboutPage ? 'Home' : 'About Me'}
							</a>
						</li>
						<li>
							<a
								href='/assets/documents/Resume.pdf'
								target='_blank'
								rel='noreferrer'
								className='hover:underline'>
								View Resume
							</a>
						</li>
					</ul>
				</div>
				<hr className='my-6 border-gray-200 sm:mx-auto lg:my-8' />
				<span className='block text-sm text-gray-500 sm:text-center'>
					© 2023{' '}
					<a href='/' className='hover:underline'>
						altaaf.dev™
					</a>
					. All Rights Reserved.
				</span>
			</div>
		</footer>
	);
};

export default Footer;
